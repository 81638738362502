.bootstrap-v4-wrapper {
    @import "../../../../node_modules/bootstrap4/scss/bootstrap";

    //we do this to override ace bullshit
    @each $color, $value in $theme-colors {
        .badge-#{$color} {
            background-color: $value !important;
        }
        
        .btn-#{$color} {
            border-color: $value !important;
            background-color: $value !important;
        }
    }

    input[type=range]::-webkit-slider-thumb {
        background-color: #57c5f1 !important;
    }

    input[type=range]::-moz-range-thumb {
        background-color: #57c5f1 !important;
    }

    input[type=range]::-ms-thumb {
        background-color: #57c5f1 !important;
    }
}